<template>
  <div class="error-404-container">
    <img src="@/assets/images/404.svg" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.error-404-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
